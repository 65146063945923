import React from "react";
import PostTemplate from "../../Template/page-template";

import thumbnail from "./thumbnail.jpg";

class Bildungsgerechtigkeit extends React.Component {

  constructor(props) {
    super(props);
    this.type = this.props.type;
    this.to = this.props.to;
  }

  render() {
    return (
      <PostTemplate 
        title="Workshop Bildungsgerechtigkeit"
        thumbnail={thumbnail}
        thumbnail_description="Thumbnail"
        timeOfPublishment="26.08.2022"
        type={this.type}
        to={this.to}
      >
        <p>
        Die Schule ist das mächtigste Mittel der Befreiung, und die Schule ist das mächtigste Mittel der Knechtung — je nach der Natur und dem Zweck des Staats. Im freien Staat ein Mittel der Befreiung, ist die Schule im unfreien Staat ein Mittel der Knechtung. ‚Bildung macht frei‘ — von dem unfreien Staat verlangen, daß er das Volk bilde, heißt ihm einen Selbstmord zumuthen. Der moderne Klassenstaat bedingt aber seinem Wesen nach die Unfreiheit. (…). Er kann freie Männer nicht brauchen, nur gehorsame Unterthanen; nicht Charaktere, nur Bedienten- und Sklavenseelen. Da ein ‚intelligenter‘ Bedienter und Sklave brauchbarer ist als ein unintelligenter — schon die Römer legten auf Sklaven, die etwas gelernt hatten, einen besonderen Werth und zahlten entsprechende Preise für sie —, sorgt der moderne Staat für eine gewisse Intelligenz, nämlich für Bedienten-Intelligenz, die das menschliche Werkzeug verfeinert und vervollkommnet, so daß sich besser mit ihm ‚arbeiten‘ läßt. So wird die Schule zur Dressuranstalt statt zur Bildungsanstalt. Statt Menschen zu erziehen, erzieht sie Rekruten, die auf’s Kommando in die Kaserne, diese Menschen-Maschinenfabrik, eilen; Steuerzahler, die sich nicht mucksen, wird ihnen das Fell über die Ohren gezogen; Lohnsklaven des Kapitals, die es in der Ordnung finden, daß ihnen das Mark aus den Knochen gesogen wird.
        </p>
      </PostTemplate>
    );
  }

}

export default Bildungsgerechtigkeit;