import React from "react";
import PostTemplate from "../../Template/page-template";

import "./36-BDK.scss";

import bdkThumbnail from "./sharepic.png";

class BDK36 extends React.Component {

  constructor(props) {
    super(props);
    this.type = this.props.type;
    this.to = this.props.to;
  }

  render() {
    return (
      <PostTemplate 
        title="Einladung zur 36. Bezirksdelegiertenkonferenz"
        thumbnail={bdkThumbnail}
        thumbnail_description="Sharepic für die Einladung zur 36. BDK"
        timeOfPublishment="15.08.2022"
        type={this.type}
        to={this.to}
      >
        <p>
          Die 36. BDK der BSV Düsseldorf steht an.
        </p>

        <p>
          Sie findet am 13.09.2022 von 9 bis 16 Uhr in der Aula des <a href="https://www.google.com/maps/place/Lessing-Gymnasium+and+-Berufskollegs+Dusseldorf+e.V./@51.216255,6.7949975,17z/data=!3m1!4b1!4m5!3m4!1s0x47b8ca34505aa7dd:0x1c35a9d8eb30f67c!8m2!3d51.2162591!4d6.7971915">{">"}Lessing Gymnasiums{"<"}</a> statt.
        </p>

      </PostTemplate>
    );
  }

}

export default BDK36;