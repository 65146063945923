import React from "react";
import PostTemplate from "../../Template/page-template";
import downloadButton from "./../../../../media/svgs/file_download_white_24dp.svg";

import file from "./FlyerHerbst2022final.pdf"
import sharepic from "./adam-wilson-wCKzi8nDkw8-unsplash.jpg";
import IconButton from "../../../reusables/IconButton/button";


class DieWeltBrenntWasTun extends React.Component {

  constructor(props) {
    super(props);
    this.type = this.props.type;
    this.to = this.props.to;
  }

  render() {
    return (
      <PostTemplate 
        title="Die Welt brennt; Was tun?"
        thumbnail={sharepic}
        thumbnail_description="Die Welt brennt"
        timeOfPublishment="15.11.2022"
        type={this.type}
        to={this.to}
      >
        <p>Seit 50 Tagen protestieren vor allem Frauen im Iran
für mehr Frauenrechte und Demokratie. Die
Demonstrant:innen werden für Ihren Protest verhaftet
und erschossen. Bisher sind es 14.000 Verhaftete und
300 Tote (47 davon jünger als 18). Es steht fest das
diese feministische Revolution nicht scheitern darf.
Deshalb müssen wir auf die katastrophale Situation im
Iran aufmerksam machen und fordern: Hoch die
Internationale Solidarität! Frau – Leben – Freiheit</p>
<p>
Neben Terrorherrschaft und Frauenunterdrückung
plagt die massive Dürre das Land. Ein Problem, vor
dem immer mehr Länder weltweit stehen. Die Politik
handelt gar nicht außer mit leeren Versprechungen,
obwohl wir schon mittendrin stehen. Mitten im
Geschehen der Klimakrise. Fridays for Future
verbreitete den Schulstreik als Mittel um auf das
Problem aufmerksam zu machen. An sich klappte das,
aber führte nur dazu, dass unter Konzernen und
Politikern grünes Design und grüne Rhetorik Standard
wurde. Die Wirtschaftsweise beruht immernoch auf
der Ausbeutung der Natur ohne Rücksicht auf
Konsequenzen, für die Profite von einigen Wenigen.
</p>
<p>
Weil wir in die Politik keine Hoffnungen setzen
können ist nun unser Aktivismus gefragt. 
</p>
<p>
Was Tun? Als Schüler:innen sind wir ein Teil der
Gesellschaft der immer wieder übergangen wird. In
den Protesten und Schulstreiks rund um FFF haben
wir gezeigt, dass wir laut und politisch sein können.
Wir als BSV setzen auf die Kraft der selbst organisierten Schüler:innen, nicht auf Berufspolitiker.
Wir sehen uns als Anlaufstelle in der wir uns als
Schüler:innen gesellschaftliche Mitbestimmung erkämpfen können. Es wurde lange genug für und gegen
uns Politik gemacht. Es wird Zeit das Schicksal dieser
Gesellschaft in die eigene Hand zu nehmen!
</p>
<p>
Melde dich bei uns oder schau bei unseren Sitzungen
vorbei und verändere die Welt. Wir treffen uns jeden
Montag um 18 Uhr im SV-Raum des Leibniz-Montessori Gymnasiums in Düsseldorf-Pempelfort,
Zugang beim Hoftor auf der Jülicher Str. 29
</p>

<IconButton icon={downloadButton} href={file}>Unser Flyer</IconButton>

      </PostTemplate>
    );
  }

}

export default DieWeltBrenntWasTun;